import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Partners from "../components/partners"
import Testimonials from "../components/testimonials"
import Program from "../components/program"
import CourseModality from "../components/course-modality"
import CourseForm from "../components/course-form"
import Faculty from "../components/faculty"
import CoverPage from "../components/cover-page-carrers.js"
import { Row } from "react-bootstrap"
import CareerTimer from "../components/career-timer"
import ReactMarkdown from "react-markdown"
import urlSlug from "url-slug"
import { VideoComponent } from "../components/videoComponent.js"

const MastersPage = ({ data: { strapiIcdaCareers: career } }) => (
  <Layout>
    <SEO title={`${career.main.title} - ICDA`} />
    <section>
      <div>
        {career.main.headerImages &&       
          <CoverPage career={career} images={career.main.headerImages.image} />
        }

        <div className="container my-5 main">
          {career.main.content && 
            <div className="mb-5">
              <ReactMarkdown
                className="format-markdown"
                source={career.main.content}
              />
            </div>
          }
          {career.timer && career.timer.timerCareer.length > 0 ? (
            <CareerTimer timer={career.timer} />
          ) : null}
        </div>
      </div>
      
      {career.video &&
        <div>
          <VideoComponent videoComponent={career.video}/>
        </div>
      }
      <div className="bg-secondary text-white program">
        <div className="container">
          {career.program && (
            <div className="py-5">
              <h1 className="text-center mb-4 title">{career.program.title}</h1>
              {career.program.summary ? (
                <div className="text-center mb-4">
                  <ReactMarkdown
                    className="format-markdown"
                    source={career.program.summary}
                  />
                </div>
              ) : null}
              <Row>
                <Program data={career.program.itemProgram} />
              </Row>
            </div>
          )}
        </div>
      </div>
      <div className="bg-dark text-white program">
        <div className="container">
          {career.modality && (
            <div className="py-5">
              <h1 className="text-center mb-4 title">
                {career.modality.title}
              </h1>
              {career.modality.summary ? (
                <div className="text-center mb-4">
                  <ReactMarkdown
                    source={career.modality.summary}
                    className="format-markdown"
                  />
                </div>
              ) : null}
              <CourseModality data={career.modality.itemModality} />
            </div>
          )}
        </div>
      </div>

      {career.professionals && career.professionals.length > 0 ? (
        <Faculty slug={urlSlug(career.slug)} />
      ) : null}

      <div className="fix-navbarscroll" id="form">
        <div className="container my-5">
          <CourseForm
            webcentrix={career.webcentrix}
            description={career.main.form}
          />
        </div>
      </div>

      {career.testimonials && career.testimonials.length > 0 ? (
        <div className="container">
          <Testimonials testimonials={career.testimonials} />
        </div>
      ) : null}
      <div className="container">
        <Partners />
      </div>
    </section>
  </Layout>
)

export default MastersPage

export const mastersQuery = graphql`
  query($id: String) {
    strapiIcdaCareers(id: { eq: $id }) {
      id
      name
      slug
      calendlyLink
      webcentrix {
        level
        academicUnit
        career
        agent
      }
      main {
        title
        slogan
        mailForm
        keywords
        form
        content
        headerImages {
          image {
            url
          }
        }
      }
      timer {
        visible
        title
        timerCareer {
          id
          visible
          description
          date
        }
      }
      program {
        id
        title
        summary
        itemProgram {
          title
          summary
        }
        id
      }
      modality {
        id
        title
        summary
        itemModality {
          id
          summary
          title
        }
      }
      career_category {
        name
      }
      admission {
        id
        name
        requirements {
          id
          summary
          List {
            summary
            title
            id
          }
        }
        process {
          id
          summary
          Accordion {
            id
            summary
            title
          }
        }
      }
      testimonials {
        name
        testimonial {
          company
          content
          position
          visible
        }
      }
      professionals {
        id
      }
      video {
        id
        visible
        title
        description
        videoId
        mediaVideo {
          url
        }
      }
    }
  }
`
